/* eslint-disable */
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import JobLandingRightPanel from "../JobLandingRightPanel/JobLandingRightPanel";
// import use translation ------------------------------
import { useTranslation } from "react-i18next";
import JobListCard from "./Card/JobListCard";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData, putData } from "utils/Gateway";
import JobListPlaceHolder from "./PlaceHolder/JobListPlaceHolder";
import { assetImages } from "constants";

const JobLandingMiddlePanel = () => {
  const { t } = useTranslation(); // translation marker
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [isLoading, setIsLoading] = useState(false);
  const [jobList, setJobList] = useState([]);

  const [selectedJobId, setselectedJobId] = useState(null);

  //function for get project list
  const getAllChallenges = async () => {
    setIsLoading(true);
    try {
      let requestUrl = `${url.API_BASE_URL}${url.API_GET_ALL_CHALLENGE}?token=${token}&userrolestring=${userInfo.role.name}`;

      // requestUrl +=
      //   filterDateRange != "" ? `&filterdates=${filterDateRange}` : "";

      // if (filterTitle != "") {
      //   requestUrl = requestUrl + `&filtertitle=${filterTitle}`;
      // }
      // if (filterTags.length > 0) {
      //   requestUrl = requestUrl + `&filtertags=${filterTags}`;
      // }
      // if (filterMod) {
      //   requestUrl = requestUrl + `&filtermod=${filterMod}`;
      // }

      console.log(requestUrl);

      const response = await getData(requestUrl);
      setIsLoading(false);

      console.log(response);

      if (response.status) {
        setJobList(response.data);
        if (response.data.length > 0) {
          setselectedJobId(response.data[0]._id);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const onJobSelect = (id) => {
    setselectedJobId(id);
  };

  useEffect(() => {
    getAllChallenges();
  }, []);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_CHALLENGE")
  ) {
    return (
      <div className="pnl_mdlpnl job_rightpnl">
        {/* <div className="back_btn">
          <Link to="#">
            <i className="material-icons-outlined">arrow_back</i>
            <span>{t("Back")}</span>
          </Link>
        </div> */}
        {jobList.length === 0 ? (
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                Sorry....! No job availbale now
              </p>
            </div>
          </div>
        ) : (
          <div className="post_item_outer">
            <div className="post_item">
              <div className="job_outer">
                <div className="job_leftpnl">
                  {isLoading
                    ? [1, 2, 3].map((count, index) => {
                        return <JobListPlaceHolder key={index} />;
                      })
                    : jobList.length === 0
                    ? null
                    : jobList.map((job, index) => {
                        return (
                          <JobListCard
                            key={index}
                            job={job}
                            onJobSelect={onJobSelect}
                          />
                        );
                      })}
                </div>

                {/* ----------- right panel ----------- */}
                {selectedJobId ? (
                  <JobLandingRightPanel selectedJobId={selectedJobId} />
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="pnl_mdlpnl">
        <div className="empty_access text-center">
          <div className="empty_pic mb-4">
            {" "}
            <img src={assetImages.emptyVector} alt="" />
          </div>
          <div className="empty_text">
            <p className="fs-lg text-gray fw-semibold mb-4">
              Sorry....! You don't have privilege to see this content
            </p>
          </div>
        </div>
      </div>
    );
  }
};

export default JobLandingMiddlePanel;
