import React from "react";
import AppUserLeftPanel from "../LeftPanel/AppUserLeftPanel";
import AppUserMiddlePanel from "../MiddlePanel/AppUserMiddlePanel";
import AppUserRightPanel from "../RightPanel/AppUserRightPanel";

const AppUserListBody = ({ params }) => {
  return (
    <section className="feed_pnltotal padding_130">
      <div className="container-fluid">
        <div className="pnl_outer">
          <AppUserLeftPanel />
          <AppUserMiddlePanel params={params} />
          <AppUserRightPanel />
        </div>
      </div>
    </section>
  );
};

export default AppUserListBody;
