/* eslint-disable */
import React from "react";
import EventLandingLeftPanel from "../EventLandingLeftPanel/EventLandingLeftPanel";
import EventLandingRightPanel from "../EventLandingRightPanel/EventLandingRightPanel";
import AllFilterModal from "components/common/Modals/AllFilterModal";
import EventLandingMiddlePanel from "../EventLandingMiddlePanel/EventLandingMiddlePanel";

const EventLandingBody = ({ params }) => {
  return (
    <section className="feed_pnltotal padding_130">
      <div className="container-fluid">
        <div className="pnl_outer">
          {/* ------- left panel --------- */}
          <EventLandingLeftPanel />

          {/* -------- middle panel ---------- */}
          <EventLandingMiddlePanel params={params} />

          {/* -------- right panel --------- */}
          <EventLandingRightPanel />
        </div>
      </div>
      {/* -------- all filter modal -------- */}
      <AllFilterModal />
    </section>
  );
};

export default EventLandingBody;
