import React from "react";
import CompanyLandingLeftPanel from "../CompanyLandingLeftPanel/CompanyLandingLeftPanel";
import CompanyLandingMiddlePanel from "../CompanyLandingMiddlePanel/CompanyLandingMiddlePanel";
import CompanyLandingRightPanel from "../CompanyLandingRightPanel/CompanyLandingRightPanel";
import AllFilterModal from "components/common/Modals/AllFilterModal";

const CompanyLandingBody = ({ params }) => {
  return (
    <section className="feed_pnltotal padding_130">
      <div className="container-fluid">
        <div className="pnl_outer">
          {/* --- left panel --- */}
          <CompanyLandingLeftPanel />

          {/* --- Middle panel --- */}
          <CompanyLandingMiddlePanel params={params} />

          {/* ---- right panel ---- */}
          <CompanyLandingRightPanel />
        </div>
      </div>

      {/* -------- all filter modal -------- */}
      <AllFilterModal />
    </section>
  );
};

export default CompanyLandingBody;
