/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";

import GroupLandingLeftPanel from "../GroupLandingLeftPanel/GroupLandingLeftPanel";
import GroupLandingMiddlePanel from "../GroupLandingMiddlePanel/GroupLandingMiddlePanel";
import GroupLandingRightPanel from "../GroupLandingRightPanel/GroupLandingRightPanel";
import AllFilterModal from "components/common/Modals/AllFilterModal";

const GroupLandingBody = ({ params }) => {
  return (
    <section className="feed_pnltotal padding_130">
      <div className="container-fluid">
        <div className="pnl_outer">
          {/* --- left panel --- */}
          <GroupLandingLeftPanel />

          {/* --- Middle panel --- */}
          <GroupLandingMiddlePanel params={params} />

          {/* ---- right panel ---- */}
          <GroupLandingRightPanel />
        </div>
      </div>
      {/* -------- all filter modal -------- */}
      <AllFilterModal />
    </section>
  );
};

export default GroupLandingBody;
