import React from 'react';
import CourseLandingLeftPanel from '../CourseLandingLeftPanel/CourseLandingLeftPanel';
import CourseLandingMiddlePanel from '../CourseLandingMiddlePanel/CourseLandingMiddlePanel';
import CourseLandingRightPanel from '../CourseLandingRightPanel/CourseLandingRightPanel';
import AllFilterModal from 'components/common/Modals/AllFilterModal';

const CourseLandingBody = () => {
  return (
    <section className="feed_pnltotal padding_130">
      <div className="container-fluid">
        <div className="pnl_outer">
          {/* --- left panel --- */}
          <CourseLandingLeftPanel />

          {/* --- Middle panel --- */}
          <CourseLandingMiddlePanel />

          {/* ---- right panel ---- */}
          <CourseLandingRightPanel />
        </div>
      </div>

      {/* -------- all filter modal -------- */}
      <AllFilterModal />
    </section>
  );
};

export default CourseLandingBody;
