/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

/*------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

//import images
import { assetImages } from "constants";

import CompanyReviewModal from "../Modal/CompanyReviewModal";
import CompanyListCard from "./Card/CompanyListCard";
import CompanyListPlaceHolder from "./PlaceHolder/CompanyListPlaceHolder";
import CompanyFilterPopup from "../Popup/CompanyFilterPopup";

const CompanyLandingMiddlePanel = ({ params }) => {
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [companyTypeList, setCompanyTypeList] = useState([]);
  const [tagList, setTagList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [companyList, setCompanyList] = useState([]);

  const [filterCompanyName, setFilterCompanyName] = useState("");
  const [filterCompanyTypes, setFilterCompanyTypes] = useState([]);
  const [filterCompanyTags, setFilterCompanyTags] = useState([]);
  const [filterRating, setFilterRating] = useState("");

  const glowCount = [1, 2, 3];

  //function for get all roles
  const getAllCompanyType = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_COMPANY_TYPE + `?token=${token}`;

      const response = await getData(requestUrl);

      if (response.status) {
        setCompanyTypeList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all contacts
  const getAllCompanyList = async (start, limit, sortingBy) => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_COMPANY +
        `?token=${token}&start=${start}&limit=${limit}&sortingby=${sortingBy}`;

      if (filterCompanyName != "") {
        requestUrl = requestUrl + `&filtername=${filterCompanyName}`;
      }

      if (filterCompanyTypes.length > 0) {
        requestUrl = requestUrl + `&filtertypes=${filterCompanyTypes}`;
      }

      if (filterCompanyTags.length > 0) {
        requestUrl = requestUrl + `&filterlabels=${filterCompanyTags}`;
      }

      if (filterRating != "") {
        requestUrl = requestUrl + `&filterrating=${filterRating}`;
      }

      // console.log("url of app user list------>", requestUrl);

      const response = await getData(requestUrl);

      // console.log("response in app user list------>", response);

      setIsLoading(false);
      if (response.status) {
        if (start == "0") {
          setCompanyList(response.data);
        } else {
          setCompanyList([...companyList, ...response.data]);
        }

        resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const resetList = () => {
    getAllCompanyList("0", "10", null);
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterCompanyName("");
    setFilterCompanyTypes([]);
    setFilterCompanyTags([]);
    setFilterRating("");
  };

  useEffect(() => {
    if (
      filterCompanyName != "" ||
      filterCompanyTypes.length > 0 ||
      filterCompanyTags.length > 0 ||
      filterRating != ""
    ) {
      getAllCompanyList("0", "10", null);
    }
  }, [filterCompanyName, filterCompanyTypes, filterCompanyTags, filterRating]);

  //use effect for params
  useEffect(() => {
    if (params?.sortby) {
      getAllCompanyList("0", "10", params?.sortby);
    }
  }, [params]);

  useEffect(() => {
    getAllTags();
    getAllCompanyType();
    getAllCompanyList("0", "10", null);
  }, []);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_COMPANY")
  ) {
    return (
      <div className="pnl_mdlpnl">
        {/* <div className="back_btn">
        <Link to="#">
          <i className="material-icons-outlined">arrow_back</i>
          <span>Back</span>
        </Link>
      </div> */}
        <div className="post_item_outer">
          <div className="post_item">
            {isLoading ? (
              <div className="activity_innr">
                {glowCount.map((glow, index) => {
                  return <CompanyListPlaceHolder key={index} />;
                })}
              </div>
            ) : (
              <div className="activity_innr">
                {companyList.map((company, index) => {
                  return <CompanyListCard key={index} company={company} />;
                })}
              </div>
            )}
          </div>
          <button
            className="mt-2 btn btn-primary"
            onClick={() => {
              getAllCompanyList(
                companyList.length.toString(),
                "2",
                params?.sortby ? params?.sortby : null
              );
            }}
          >
            Next Page
          </button>
        </div>
        <CompanyFilterPopup
          setFilterCompanyName={setFilterCompanyName}
          setFilterCompanyTypes={setFilterCompanyTypes}
          setFilterCompanyTags={setFilterCompanyTags}
          setFilterRating={setFilterRating}
          tagList={tagList}
          companyTypeList={companyTypeList}
          resetList={resetList}
        />
      </div>
    );
  } else {
    return (
      <div className="pnl_mdlpnl">
        <div className="empty_access text-center">
          <div className="empty_pic mb-4">
            {" "}
            <img src={assetImages.emptyVector} alt="" />
          </div>
          <div className="empty_text">
            <p className="fs-lg text-gray fw-semibold mb-4">
              Sorry....! You don't have privilege to see this content
            </p>
          </div>
        </div>
      </div>
    );
  }
};

export default CompanyLandingMiddlePanel;
