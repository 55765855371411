/* eslint-disable */

/* --------------- importing Images here --------------- */
import bannerBg from "../assets/images/banner-bg.jpg";
import bannerLogo1 from "../assets/images/banner-logo1.png";
import bannerLogo2 from "../assets/images/banner-logo2.png";
import bannerLogo3 from "../assets/images/banner-logo3.png";
import bannerLogo4 from "../assets/images/banner-logo4.png";
import bannerPic from "../assets/images/baner-pic.png";
import roadmap from "../assets/images/roadmap.jpg";
import logoKit from "../assets/images/logo-kit-digital.png";
import defaultUser from "assets/images/default-user.png";
import contactDots from "assets/images/contact-dots.png";
import dotBlueBg from "assets/images/dotblue-bg.jpg";
import congratsImage from "assets/images/congrats.png";
import modalBg from "assets/images/modal-bg.png";
import activityOne from "assets/images/activity1.jpg";
import aiLogo from "assets/images/ai-logo.png";
import emptyVector from "assets/images/empty-vector.png";
import leftBlank from "assets/images/left-blank.png";
import certificateStamp from "assets/images/certificate_stamp.png";
import signCopy from "assets/images/signed-copy.png";
import lineImage from "assets/images/line.png";
import emptyMessage from "assets/images/empty-pic.png";
import emptyGroupMessage from "assets/images/nothing2.png";
import uploadImg1 from "assets/images/upload1.jpg";
import relationShipModelTab from "assets/images/reletionship-model-tab.png";
import studyImg from "assets/images/study.png";
import statregyImg from "assets/images/statregy.png";
import userExpImg from "assets/images/user-experience.png";
import technicalSolutionImg from "assets/images/technical-solution.png";
import productLaunch from "assets/images/product-launch.png";
import whyLobees from "assets/images/why-lobess.jpg";
import komaImg from "assets/images/koma.png";
import userImg from "assets/images/user.png";
import connectBannerDotsImg from "assets/images/banner-dots.png";
import talentImg from "assets/images/talent.png";
import searchingJobImg from "assets/images/searching-job.png";
import connectOfferOne from "assets/images/connect-ofr1.png";
import connectOfferTwo from "assets/images/connect-ofr2.png";
import developFreelancerImg from "assets/images/develop-freelancer.png";
import developTeamImg from "assets/images/develop-team.png";
import timeLineImg from "assets/images/timeline.png";
import collerateImg from "assets/images/collaoarte.png";
import processPic1 from "assets/images/process-pic1.png";
import processPic2 from "assets/images/process-pic2.png";
import processPic3 from "assets/images/process-pic3.png";
import storiesShareImg from "assets/images/stories-share-bg.jpg";
import processWork1 from "assets/images/process-work1.png";
import processWork2 from "assets/images/process-work2.png";
import processWork3 from "assets/images/process-work3.png";
import img01 from "assets/images/01.png";
import img02 from "assets/images/02.png";
import img03 from "assets/images/03.png";

/* --------------- importing SVGs here --------------- */
import logo from "../assets/svg/logo.svg";
import userIcon1 from "../assets/icons/userIcon1.svg";
import userIcon2 from "../assets/icons/userIcon2.svg";
import userIcon3 from "../assets/icons/userIcon3.svg";
import kitIcon1 from "../assets/icons/kitIcon1.svg";
import kitIcon2 from "../assets/icons/kitIcon2.svg";
import kitIcon3 from "../assets/icons/kitIcon3.svg";
import kitIcon4 from "../assets/icons/kitIcon4.svg";
import kitIcon5 from "../assets/icons/kitIcon5.svg";
import kitIcon6 from "../assets/icons/kitIcon6.svg";
import kitIcon7 from "../assets/icons/kitIcon7.svg";
import kitIcon8 from "../assets/icons/kitIcon8.svg";
import kitIcon9 from "../assets/icons/kitIcon9.svg";
import fv from "../assets/icons/fb.svg";
import twitter from "../assets/icons/twitter.svg";
import linkedin from "../assets/icons/linkedin.svg";
import globalLogo from "../assets/icons/global-logo.svg";
import rewardLogo from "assets/svg/rewads.svg";
import menuIcon from "assets/svg/menu.svg";
import brandLogo1 from "assets/svg/brand-logo1.svg";
import brandLogo2 from "assets/svg/brand-logo2.svg";
import brandLogo3 from "assets/svg/brand-logo3.svg";
import brandLogo4 from "assets/svg/brand-logo4.svg";
import brandLogo5 from "assets/svg/brand-logo5.svg";
import digitalKitLogo from "assets/svg/digital-kit-logo.svg";
import PdfImg from "assets/svg/pdf.svg";
import XlsImg from "assets/svg/xls.svg";
import DocImg from "assets/svg/docs.svg";
import emptyDataPic from "assets/svg/lesson-empty.svg";
import embedIcon from "assets/svg/code.svg";
import whatsAppIcon from "assets/svg/whatsapp.svg";
import skypIcon from "assets/svg/skype.svg";
import facebookIcon from "assets/svg/share-facebook.svg";
import twitterIcon from "assets/svg/share-twitter.svg";
import instagramIcon from "assets/svg/share-instagram.svg";
import helloWaveSvg from "assets/svg/hlw-wave.svg";
import paymentSuccessLogo from "assets/svg/success-icon.svg";
import oopsIcon from "assets/svg/opps-icon.svg";
import aiLogoSvg from "assets/svg/ai-logo.svg";

export default {
  // ------------------ Exporting Images here ------------------
  bannerBg,
  bannerLogo1,
  bannerLogo2,
  bannerLogo3,
  bannerLogo4,
  bannerPic,
  roadmap,
  logoKit,
  defaultUser,
  contactDots,
  dotBlueBg,
  congratsImage,
  modalBg,
  activityOne,
  aiLogo,
  emptyVector,
  leftBlank,
  certificateStamp,
  signCopy,
  lineImage,
  emptyMessage,
  emptyGroupMessage,
  uploadImg1,
  relationShipModelTab,
  studyImg,
  statregyImg,
  userExpImg,
  technicalSolutionImg,
  productLaunch,
  whyLobees,
  komaImg,
  userImg,
  connectBannerDotsImg,
  talentImg,
  searchingJobImg,
  connectOfferOne,
  connectOfferTwo,
  developFreelancerImg,
  developTeamImg,
  timeLineImg,
  collerateImg,
  processPic1,
  processPic2,
  processPic3,
  storiesShareImg,
  processWork1,
  processWork2,
  processWork3,
  img01,
  img02,
  img03,

  // ------------------ Exporting SVGs here ------------------
  logo,
  userIcon1,
  userIcon2,
  userIcon3,
  kitIcon1,
  kitIcon2,
  kitIcon3,
  kitIcon4,
  kitIcon5,
  kitIcon6,
  kitIcon7,
  kitIcon8,
  kitIcon9,
  fv,
  twitter,
  linkedin,
  globalLogo,
  rewardLogo,
  menuIcon,
  brandLogo1,
  brandLogo2,
  brandLogo3,
  brandLogo4,
  brandLogo5,
  digitalKitLogo,
  PdfImg,
  XlsImg,
  DocImg,
  emptyDataPic,
  embedIcon,
  whatsAppIcon,
  skypIcon,
  facebookIcon,
  twitterIcon,
  instagramIcon,
  paymentSuccessLogo,
  oopsIcon,
  helloWaveSvg,
  aiLogoSvg,
};
