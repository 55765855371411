/* eslint-disable */
import { useState } from "react";

const FetchFromDocModal = ({
  token,
  url,
  uploadSingleFile,
  setUploadedDocUrl,
  setDocContent
}) => {

  const [isUploading, setIsUploading] = useState(false);


  //** upload doc funtion
  const docUploadHandler = async (e) => {
    try {

      setIsUploading(true);

      // reset dependent data to avoid duplicate
      setUploadedDocUrl('');
      setDocContent('');


      const file = e.target.files[0];

      let requestURL =
        url.API_BASE_URL + url.API_SINGLE_FILE_UPLOAD + `?token=${token}&textcontent=1`;

      console.log('req url', requestURL);  

      const response = await uploadSingleFile(requestURL, file);

      if (response.status) {

        console.log('file upload response', response);

        // set the uploaded url path
        setUploadedDocUrl(response.data.path);
        // set the content of the file here
        setDocContent(response.textcontent);

        setIsUploading(false);

        let uploadModal = document.querySelector("#fetch_from_doc");
        let modal = bootstrap.Modal.getInstance(uploadModal);
        modal.hide();

      }
    } catch (error) {
      console.log(error.message);
    }
    resetDocFileInput();
  };

  //** function for reset video inputs
  const resetDocFileInput = () => {
    const file = document.getElementById("doc_file_input");
    if (file) {
      file.value = null;
    } else {
      return;
    }
  };

  return (
    <div className="post_modal">
      <div
        className="modal fade"
        id="fetch_from_doc"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              {/* <!-- ========== Start modal title Section ========== --> */}
              <h3 className="modal-title" id="exampleModalLabel">
                Upload CV 
              </h3>
              
              {/* <!-- ========== End modal title Section ========== --> */}

              {/* <!-- ========== Start modal close button ========== --> */}
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setUploadedDocUrl("");
                }}
              >
                <span className="material-icons-outlined">close</span>
              </button>
              {/* <!-- ========== End modal close button ========== --> */}
            </div>
            <div className="modal-body">
              <h5 className="text text-danger mb-2">
                <span className="material-icons-outlined me-2"> info </span>
                in pdf / txt / docx format only
              </h5>

              {/* <!-- ========== Start file upload Section ========== --> */}
              <div className="upload_imgbx">
                <input
                  type="file"
                  id="doc_file_input"
                  className="file_btn"
                  onChange={docUploadHandler}
                />
                <div className="post_upload_innr">
                  <div className="post_icon">
                    <span className="material-icons-outlined">
                      {" "}
                      collections{" "}
                    </span>
                  </div>
                  <h4>Add Doc</h4>
                </div>
              </div>

              <h5 className={`text text-primary mt-3 ${isUploading? '' : 'd-none'}`}>
                <div className="spinner-border me-4" role="status">
                  <span className="sr-only"></span>
                </div>
                File uploading in process
              
              </h5>

              {/* <!-- ========== End file upload Section ========== --> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FetchFromDocModal;
