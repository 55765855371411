/* eslint-disable */
import React from "react";
import { useParams } from "react-router-dom";

const ApplySection = ({ errorMessage, cvTitle, onSaveCv, isSaving, cvId }) => {
  const params = useParams();
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  return (
    <div className={`builder_item white_shadow_bx `}>
      <div className="apply_bttm_row d-flex align-items-center justify-content-between gap-2">
        <div className="event_hdng">
          {params && params.id && cvTitle ? (
            <>
              <h6 className="mb-2">Apply/Save CV as</h6>
              <h3 className="mb-2">{cvTitle}</h3>
              <h4 className="mb-0"> </h4>
            </>
          ) : (
            ""
          )}
        </div>

        <div className="d-flex align-items-center gap-2">
          <div className="apply_btn">
            {/* <button
              type="button"
              className={`btn ${params && params.id ? "" : "btn-primary"}`}
              onClick={() => {
                onSaveCv(false);
              }}
              disabled={isSaving ? true : false}
              style={{
                cursor: isSaving ? "not-allowed" : "pointer",
              }}
            >
              Save
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button> */}

            <button
              className={`btn btn-sm ${cvTitle ? "" : "d-none"}`}
              data-bs-toggle="modal"
              data-bs-target="#customertemplate_modal"
            >
              View as Customer
            </button>
          </div>

          <div className={`apply_btn ${cvTitle ? "" : "d-none"}`}>
            <button
              className={`btn btn-primary`}
              disabled={isSaving ? true : false}
              style={{
                cursor: isSaving ? "not-allowed" : "pointer",
              }}
              onClick={() => {
                onSaveCv(true);
              }}
            >
              {cvId ? "Update your cv" : "Apply with this CV"}
              {isSaving && (
                <div
                  className="mx-2 spinner-border spinner-border-sm"
                  role="status"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </button>
          </div>
        </div>
      </div>
      {errorMessage === "" ? null : (
        <div className="d-flex align-items-center justify-content-end">
          <p className="text-danger fw-semibold">* {errorMessage}</p>
        </div>
      )}
    </div>
  );
};

export default ApplySection;
