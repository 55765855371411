/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

//import context
// import { GlobalProvider } from "context/GlobalContext";

/*import url and gateway methods */
import { getData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

// import use translation ------------------------------
// import { useTranslation } from "react-i18next";

import EventListPlaceHolder from "./PlaceHolder/EventListPlaceHolder";
import EventListCard from "./Card/EventListCard";
import EventListFilterPopup from "../Popup/EventListFilterPopup";
import { assetImages } from "constants";

const EventLandingMiddlePanel = ({ params }) => {
  // const { screenHeight } = useContext(GlobalProvider);
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const placeHolderCount = [1, 2];

  const [eventTypeList, seteventTypeList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [categoryList, setcategoryList] = useState([]);
  const [searchingList, setsearchingList] = useState([]);
  const [interestList, setinterestList] = useState([]);

  const [eventList, seteventList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [filterRating, setFilterRating] = useState("");
  const [filterDateRange, setFilterDateRange] = useState("");
  const [filterEventTitle, setFilterEventTitle] = useState("");
  const [filterEventType, setFilterEventType] = useState([]);
  const [filterEventCategories, setfilterEventCategories] = useState([]);
  const [filterEventInterests, setfilterEventInterests] = useState([]);
  const [filterEventSearchings, setfilterEventSearchings] = useState([]);
  const [filterEventTags, setFilterEventTags] = useState([]);
  const [filterEventCity, setfilterEventCity] = useState("");
  const [filterEventCountry, setfilterEventCountry] = useState("");

  //function for get all event type
  const getEventTypes = async () => {
    try {
      let requestUrl = url.API_BASE_URL + url.API_GET_ALL_EVENT_TYPES;

      const response = await getData(requestUrl);

      if (response.status) {
        seteventTypeList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all category
  const getAllCategories = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CATEGORIES +
        `?token=${token}&slug=EVENT`;

      const response = await getData(requestUrl);

      if (response.status) {
        setcategoryList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const getAllSearchingInterest = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CATEGORIES +
        `?token=${token}&slug=skills`;

      const response = await getData(requestUrl);

      if (response.status) {
        setsearchingList(response.data);
        setinterestList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //get eventlist function
  const getAllEvent = async (start, limit, sortingBy) => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_ALL_EVENTS +
        `?token=${token}&start=${start}&limit=${limit}&sortingby=${sortingBy}`;

      if (filterEventTitle != "") {
        requestUrl = requestUrl + `&filtertitle=${filterEventTitle}`;
      }
      if (filterEventCity != "") {
        requestUrl = requestUrl + `&filtercity=${filterEventCity}`;
      }
      if (filterEventCountry != "") {
        requestUrl = requestUrl + `&filtercountry=${filterEventCountry}`;
      }
      if (filterDateRange != "") {
        requestUrl = requestUrl + `&filterdates=${filterDateRange}`;
      }
      if (filterRating != "") {
        requestUrl = requestUrl + `&filterrating=${filterRating}`;
      }
      if (filterEventTags.length > 0) {
        requestUrl = requestUrl + `&filtertags=${filterEventTags}`;
      }
      if (filterEventType.length > 0) {
        requestUrl = requestUrl + `&filtertype=${filterEventType}`;
      }
      if (filterEventCategories.length > 0) {
        requestUrl = requestUrl + `&filtercategory=${filterEventCategories}`;
      }
      if (filterEventInterests.length > 0) {
        requestUrl = requestUrl + `&filterinterest=${filterEventInterests}`;
      }
      if (filterEventSearchings.length > 0) {
        requestUrl = requestUrl + `&filtersearchings=${filterEventSearchings}`;
      }

      // console.log("request url for event list----->", requestUrl);

      const response = await getData(requestUrl);

      // console.log(response);

      setIsLoading(false);
      if (response.status) {
        seteventList(response.data);
        resetFilterData();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for reset filter
  const resetFilterData = () => {
    setFilterRating("");
    setFilterDateRange("");
    setFilterEventTitle("");
    setfilterEventCity("");
    setfilterEventCountry("");
    setFilterEventTags([]);
    setFilterEventType([]);
    setfilterEventCategories([]);
    setfilterEventInterests([]);
    setfilterEventSearchings([]);
  };

  const resetList = () => {
    getAllEvent("0", "10", null);
  };

  //use effect for filter search
  useEffect(() => {
    if (
      filterDateRange != "" ||
      filterEventTitle != "" ||
      filterEventCity != "" ||
      filterEventCountry != "" ||
      filterRating != "" ||
      filterEventType.length > 0 ||
      filterEventCategories.length > 0 ||
      filterEventInterests.length > 0 ||
      filterEventSearchings.length > 0 ||
      filterEventTags.length > 0
    ) {
      getAllEvent("0", "10", null);
    }
  }, [
    filterDateRange,
    filterEventTitle,
    filterEventType,
    filterRating,
    filterEventCategories,
    filterEventInterests,
    filterEventSearchings,
    filterEventTags,
    filterEventCity,
    filterEventCountry,
  ]);

  //use effect for params
  useEffect(() => {
    if (params?.sortby) {
      getAllEvent("0", "10", params?.sortby);
    }
  }, [params]);

  useEffect(() => {
    getEventTypes();
    getAllTags();
    getAllCategories();
    getAllSearchingInterest();
    getAllEvent("0", "10", null);
  }, []);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_EVENT")
  ) {
    return (
      <div className="pnl_mdlpnl">
        {/* <div className="filter_backouter">
        <div className="back_btn">
          <Link to="feed_landing.html">
            <i className="material-icons-outlined">arrow_back</i>
            <span>Back</span>
          </Link>
        </div>
        <div className="filter_btn d-none">
          <Link to="#">
            {" "}
            <i className="material-icons-outlined">filter_alt</i>
          </Link>
        </div>
      </div> */}
        <div className="post_item_outer">
          <div className="post_item">
            {isLoading ? (
              <div className="activity_innr">
                {placeHolderCount.map((count, index) => {
                  return <EventListPlaceHolder key={index} />;
                })}
              </div>
            ) : (
              <div className="activity_innr">
                {eventList.map((eventData, index) => {
                  return <EventListCard eventData={eventData} key={index} />;
                })}
              </div>
            )}
          </div>
        </div>
        {/* <button
        className="mt-2 btn btn-primary"
        onClick={() => {
          getAllAppUsers(
            appUserList.length.toString(),
            "2",
            params?.sortby ? params?.sortby : null
          );
        }}
      >
        Next Page
      </button> */}
        <EventListFilterPopup
          eventTypeList={eventTypeList}
          tagList={tagList}
          categoryList={categoryList}
          searchingList={searchingList}
          interestList={interestList}
          setfilterEventCategories={setfilterEventCategories}
          setfilterEventInterests={setfilterEventInterests}
          setfilterEventSearchings={setfilterEventSearchings}
          setfilterEventCity={setfilterEventCity}
          setfilterEventCountry={setfilterEventCountry}
          setFilterDateRange={setFilterDateRange}
          setFilterEventTags={setFilterEventTags}
          setFilterEventType={setFilterEventType}
          setFilterEventTitle={setFilterEventTitle}
          setFilterRating={setFilterRating}
          resetList={resetList}
        />
      </div>
    );
  } else {
    return (
      <div className="pnl_mdlpnl">
        <div className="empty_access text-center">
          <div className="empty_pic mb-4">
            {" "}
            <img src={assetImages.emptyVector} alt="" />
          </div>
          <div className="empty_text">
            <p className="fs-lg text-gray fw-semibold mb-4">
              Sorry....! You don't have privilege to see this content
            </p>
          </div>
        </div>
      </div>
    );
  }
};

export default EventLandingMiddlePanel;
