/* eslint-disable */
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CvBuilderLeftPanel from "../Panels/LeftPanel/CvBuilderLeftPanel";
import CvBuilderRightPanel from "../Panels/RightPanel/CvBuilderRightPanel";
import { assetImages } from "constants";

//**------ import url and method ------*/
import * as url from "helper/UrlHelper";
import { getData } from "utils/Gateway";

const CvBuilderMainPanel = () => {
  const params = useParams();
  const token = localStorage.getItem("token");

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const [cvId, setcvId] = useState(null);
  const [reloadCvList, setreloadCvList] = useState(false);
  const [startFetchFromProfile, setStartFetchFromProfile] = useState(false);
  const [cvContent, setCvContent] = useState({});

  const [jobDetailsData, setJobDetailsData] = useState({});
  const [isAgenecy, setIsAgenecy] = useState(false);

  //function for get challenge details
  const getChallengeDetails = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CHALLENGE_DETAILS +
        `/${params.id}?token=${token}`;

      const response = await getData(requestUrl);

      console.log(response);

      if (response.status) {
        setJobDetailsData(response.data);

        const userIsMod =
          response.data.moderator._id.toString() === userInfo._id.toString();

        const isMemberExists = response.data.challengemembers.some(
          (item) => item._id.toString() === userInfo._id.toString()
        );

        // console.log("User is mod---->", userIsMod);
        // console.log("User is member----->", isMemberExists);

        if (
          userIsMod ||
          isMemberExists ||
          userInfo.role.slug === "ADMIN" ||
          userInfo.role.slug === "SUPER_ADMIN"
        ) {
          setIsAgenecy(true);
        } else {
          setIsAgenecy(false);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (params.id) {
      getChallengeDetails();
    }
  }, [params.id]);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_CV")
  ) {
    return (
      <section className="feed_pnltotal">
        <div className="container-fluid">
          <div className="">
            <div className="row">
              <CvBuilderLeftPanel
                cvId={cvId}
                setcvId={setcvId}
                setreloadCvList={setreloadCvList}
                startFetchFromProfile={startFetchFromProfile}
                setStartFetchFromProfile={setStartFetchFromProfile}
                setCvContent={setCvContent}
                isAgenecy={isAgenecy}
              />
              <CvBuilderRightPanel
                setcvId={setcvId}
                reloadCvList={reloadCvList}
                setreloadCvList={setreloadCvList}
                setStartFetchFromProfile={setStartFetchFromProfile}
                cvContent={cvContent}
                jobDetailsData={jobDetailsData}
                getChallengeDetails={getChallengeDetails}
              />
            </div>
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section className="feed_pnltotal">
        <div className="container-fluid">
          <div className="empty_access text-center">
            <div className="empty_pic mb-4">
              {" "}
              <img src={assetImages.emptyVector} alt="" />
            </div>
            <div className="empty_text">
              <p className="fs-lg text-gray fw-semibold mb-4">
                Sorry....! You don't have privilege to see this content
              </p>
            </div>
          </div>
        </div>
      </section>
    );
  }
};

export default CvBuilderMainPanel;
