/* eslint-disable */
import "react-multi-carousel/lib/styles.css";

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
//import context
import { GlobalProvider } from "context/GlobalContext";

/*import url and gateway methods */
import { getData, putData } from "utils/Gateway";
import * as url from "helper/UrlHelper";

// import use translation ------------------------------
import { useTranslation } from "react-i18next";

//import images
import { assetImages } from "constants";
import AppUserCard from "./Card/AppUserCard";
import AppUserPlaceHolder from "./PlaceHolder/AppUserPlaceHolder";
import AppUserFilterPopup from "../Popup/AppUserFilterPopup";

const AppUserMiddlePanel = ({ params }) => {
  const { screenHeight, ecosystemList } = useContext(GlobalProvider);
  const token = localStorage.getItem("token");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  const moduleAccess = localStorage.getItem("moduleaccess");

  const { t } = useTranslation(); // translation marker

  const glowCount = [1, 2, 3, 4, 5, 6];

  const [skillList, setSkillList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [tagList, setTagList] = useState([]);

  const [filterName, setfilterName] = useState("");
  const [filterCompany, setfilterCompany] = useState("");
  const [filterPosition, setFilterPosition] = useState("");
  const [filterRating, setFilterRating] = useState("");
  const [filterServices, setFilterServices] = useState([]);
  const [filterSkills, setfilterSkills] = useState([]);
  const [filterTags, setFilterTags] = useState([]);
  const [filterEcosystem, setFilterEcosystem] = useState(null);

  const [appUserList, setAppUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //get all services
  const getAllServices = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CATEGORIES +
        `?token=${token}&slug=services`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setServiceList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all skills
  const getAllSkills = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_CATEGORIES +
        `?token=${token}&slug=skills`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setSkillList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all tags
  const getAllTags = async () => {
    try {
      let requestUrl =
        url.API_BASE_URL + url.API_GET_ALL_TAGS + `?token=${token}`;

      const response = await getData(requestUrl);

      // console.log(response);

      if (response.status) {
        setTagList(response.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  //function for get all contacts
  const getAllAppUsers = async (start, limit, sortingBy) => {
    try {
      setIsLoading(true);
      let requestUrl =
        url.API_BASE_URL +
        url.API_GET_APP_USERS +
        `?token=${token}&start=${start}&limit=${limit}&sortingby=${sortingBy}`;

      if (filterName != "") {
        requestUrl = requestUrl + `&filtername=${filterName}`;
      }

      if (filterCompany != "") {
        requestUrl = requestUrl + `&filtercompany=${filterCompany}`;
      }

      if (filterPosition != "") {
        requestUrl = requestUrl + `&filterposition=${filterPosition}`;
      }

      if (filterRating != "") {
        requestUrl = requestUrl + `&filterrating=${filterRating}`;
      }

      if (filterServices.length > 0) {
        requestUrl = requestUrl + `&filterservices=${filterServices}`;
      }

      if (filterSkills.length > 0) {
        requestUrl = requestUrl + `&filterskills=${filterSkills}`;
      }

      if (filterTags.length > 0) {
        requestUrl = requestUrl + `&filterlabels=${filterTags}`;
      }

      if (filterEcosystem) {
        requestUrl = requestUrl + `&filterecosystemid=${filterEcosystem}`;
      }

      console.log("url of app user list------>", requestUrl);

      const response = await getData(requestUrl);

      console.log("response in app user list------>", response);

      if (response.status) {
        if (start == "0") {
          setAppUserList(response.data);
        } else {
          setAppUserList([...appUserList, ...response.data]);
        }
        resetFilterData();
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  //reset filter
  const resetFilterData = () => {
    setfilterName("");
    setfilterCompany("");
    setFilterPosition("");
    setFilterRating("");
    setFilterServices([]);
    setfilterSkills([]);
    setFilterTags([]);
    setFilterEcosystem(null);
  };

  const resetList = () => {
    getAllAppUsers("0", "10", null);
  };

  //use effect for filter search
  useEffect(() => {
    if (
      filterName != "" ||
      filterCompany != "" ||
      filterPosition != "" ||
      filterRating != "" ||
      filterSkills.length > 0 ||
      filterServices.length > 0 ||
      filterTags.length > 0 ||
      filterEcosystem
    ) {
      getAllAppUsers("0", "10", null);
    }
  }, [
    filterName,
    filterCompany,
    filterPosition,
    filterRating,
    filterSkills,
    filterServices,
    filterTags,
    filterEcosystem,
  ]);

  //use effect for params
  useEffect(() => {
    if (params?.sortby) {
      getAllAppUsers("0", "10", params?.sortby);
    }
  }, [params]);

  useEffect(() => {
    getAllAppUsers("0", "10", null);
    getAllSkills();
    getAllServices();
    getAllTags();
  }, []);

  if (
    userInfo.role.slug === "ADMIN" ||
    userInfo.role.slug === "SUPER_ADMIN" ||
    moduleAccess.includes("MOD_USER")
  ) {
    return (
      <div className="pnl_mdlpnl">
        {/* <div className="back_btn">
        <Link to="#">
          <i className="material-icons-outlined">arrow_back</i>
          <span>{t("Back")}</span>
        </Link>
      </div> */}
        <div className="post_item_outer">
          {isLoading ? (
            <div className="post_item">
              {glowCount.map((item, index) => {
                return <AppUserPlaceHolder key={index} />;
              })}
            </div>
          ) : (
            <div className="post_item">
              {appUserList.map((user, index) => {
                return <AppUserCard key={index} userData={user} />;
              })}
            </div>
          )}
        </div>
        <button
          className="mt-2 btn btn-primary"
          onClick={() => {
            getAllAppUsers(
              appUserList.length.toString(),
              "2",
              params?.sortby ? params?.sortby : null
            );
          }}
        >
          Next Page
        </button>
        <AppUserFilterPopup
          skillList={skillList}
          serviceList={serviceList}
          tagList={tagList}
          ecosystemList={ecosystemList}
          setfilterName={setfilterName}
          setfilterCompany={setfilterCompany}
          setFilterPosition={setFilterPosition}
          setFilterRating={setFilterRating}
          setfilterSkills={setfilterSkills}
          setFilterServices={setFilterServices}
          setFilterTags={setFilterTags}
          setFilterEcosystem={setFilterEcosystem}
          resetList={resetList}
        />
      </div>
    );
  } else {
    return (
      <div className="pnl_mdlpnl">
        <div className="empty_access text-center">
          <div className="empty_pic mb-4">
            {" "}
            <img src={assetImages.emptyVector} alt="" />
          </div>
          <div className="empty_text">
            <p className="fs-lg text-gray fw-semibold mb-4">
              Sorry....! You don't have privilege to see this content
            </p>
          </div>
        </div>
      </div>
    );
  }
};

export default AppUserMiddlePanel;
